<template>
    <div>
        <div class="mb-5">
            <h2 class="mb-2">
                Data Sharing Agreement
            </h2>
            <p>
                EventFlowPLUS enables you to leverage the BuyerBridge platform to syndicate user data collected from {{ currentDealer.site_base_url }} to supported
                advertising channels. As a result we require that you acknowledge the data sharing agreement and liability waiver below to use this technology.
            </p>
        </div>
        <v-alert
            type="error"
            class="mb-5"
            :value="errorMessage">
            {{ errorMessage }}
        </v-alert>
        <loader v-if="loading" />
        <template v-else>
            <div class="agreement-content">
                <div class="pa-4">
                    <div v-html="parsedLegalBody" />
                </div>
            </div>
            <div class="mt-4 mb-5 flex">
                <div>
                    <styled-checkbox
                        :value="checkBoxValue"
                        :disabled="checkBox || loading"
                        :checked.sync="checkBoxValue"
                        square />
                </div>
                <div class="mb-1 ml-3">
                    Click here to confirm that you accept the agreement above and
                    understand that you {{ currentDealer.agency.name }} is responsible for any
                    data privacy agreements between {{ currentDealer.agency.name }} and visitors of {{ currentDealer.site_base_url }}
                </div>
            </div>
        </template>
        <div>
            <div class="mt-5 layout align-center">
                <styled-button
                    class="ml-auto"
                    :disabled="loading || !checkBoxValue"
                    @click="submit">
                    <span v-if="!checkBox">ACCEPT AGREEMENT &</span> CONTINUE
                </styled-button>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import Loader from '@/components/globals/Loader';
import StyledButton from '@/components/globals/StyledButton';
import StyledCheckbox from '@/components/globals/StyledCheckbox';

export default {
    components: {
        Loader,
        StyledButton,
        StyledCheckbox,
    },
    data() {
        return {
            loading: false,
            checkBox: null,
            checkBoxValue: false,
            errorMessage: null,
            parsedLegalBody: null,
            legalAgreementData: null,
            legalAgreementName: 'event_flow_plus_data_sharing_agreement'
        };
    },
    computed: {
        ...mapState({
            currentDealer: state => state.dealer.currentDealer
        })
    },
    async mounted() {
        await this.getLegalAgreementData();
        await this.getDealerLegalAgreementStatus();
    },
    methods: {
        async getLegalAgreementData() {
            try {
                this.loading = true;
                const agreement = await this.$apiRepository.getLegalAgreement(this.legalAgreementName);
                this.legalAgreementData = agreement;
                this.parseLegalBody();
            } catch (error) {
                console.error('Error getting the legal agreement data', error);
                this.errorMessage = 'Error getting the legal agreement data';
            } finally {
                this.loading = false;
            }
        },
        async getDealerLegalAgreementStatus() {
            try {
                this.loading = true;
                const response = await this.$apiRepository.getDealerAgreement(this.currentDealer.id, this.legalAgreementName);
                this.checkBox = !!response.data?.id;
                this.checkBoxValue = this.checkBox;
            } catch (error) {
                console.error('Error getting the legal agreement status', error);
                this.errorMessage = 'Error getting the legal agreement status';
            } finally {
                this.loading = false;
            }
        },
        async acceptAgreement() {
            try {
                this.loading = true;
                const data = {
                    dealerId: this.currentDealer.id,
                    legalAgreementName: this.legalAgreementData.name,
                    document: this.parsedLegalBody,
                };
                const response = await this.$apiRepository.acceptDealerAgreement(data);
                if (response.data?.success) {
                    this.checkBox = true;
                }
            } catch (error) {
                console.error('Error updating the legal agreement status', error);
                this.errorMessage = 'Error updating the legal agreement status';
            } finally {
                this.loading = false;
            }
        },
        parseLegalBody() {
            const variables = {
                '{{agency_name}}': this.currentDealer.agency.name,
                '{{dealer_domain}}': this.currentDealer.site_base_url,
            };
            if (this.legalAgreementData) {
                this.parsedLegalBody = this.legalAgreementData.body.replace(/{{agency_name}}|{{dealer_domain}}/g, (matched) => {
                    return variables[matched];
                });
            }
        },
        async submit() {
            if (!this.checkBox) {
                await this.acceptAgreement();
            }
            this.$store.dispatch('goToNextStep');
        }
    }
};
</script>

<style lang="scss" scoped>
.agreement-content {
    color: $gray-dark;
    border: 1px solid rgba($gray, 0.4);
    font-size: 14px;
    background-color: rgba($gray, 0.05);
    font-family:'Courier New', Courier, monospace;
    h1, h2, h3, h4 {
        font-weight: bold;
        margin-bottom: 1rem;
        color: $gray-dark;
    }
}
.flex {
    display: flex;
    align-items: center;
}
</style>